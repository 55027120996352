import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProductListInState,
  updateProductListSequenceMode,
} from "../store/product-list/ProductListActions";
import { selectEditSequenceList } from "../store/product-list/ProductListSelectors";
import { Typography, FormControl } from "@mui/material";
import { useIntl } from "react-intl";
import { SmartSortList, SEQUENCE_MODE_TYPE } from "../utils/Constants";
import { styles, useStyles } from "./SmartSort.styles";
import DropdownMenu from "./common/DropdownMenu";
import {
  selectProductClusterListFetched,
  selectedSmartSortValue,
} from "store/ai-cluster/AiClusterSelectors";
import { getUpdatedProductList } from "utils/SmartSortUtils";
import { setSelectedSmartSort } from "store/ai-cluster/AiClusterAction";

const SmartSort = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { bodyWrap } = styles();

  const dispatch = useDispatch();
  const products = useSelector(selectEditSequenceList);
  const selectedSmartSort = useSelector(selectedSmartSortValue);
  const productSimilarity = useSelector(selectProductClusterListFetched);
  const handleSmartSort = (event) => {
    const selectedValue = event.target.value;
    dispatch(setSelectedSmartSort(selectedValue));
    const updatedProductList = getUpdatedProductList(
      products,
      productSimilarity,
      selectedValue,
    );
    const payload = {
      products: updatedProductList,
      isSaveAction: false,
    };
    dispatch(updateProductListInState(payload));
    dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.sortClass}>
          <FormControl sx={bodyWrap}>
            <label>
              <Typography variant="subHeader">
                {intl.formatMessage({
                  id: "smartSort.label",
                  defaultMessage: "Move",
                })}
              </Typography>
            </label>
            <DropdownMenu
              menuId="SmartSortTools"
              value={selectedSmartSort}
              changeHandler={handleSmartSort}
              smartSortMenuList={SmartSortList}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SmartSort);
