import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  selectCategories,
  Category,
  selectChildCategories,
  selectTopCategoriesFetched,
  selectTopCategories,
  selectIsCatalogOrStoreChanged,
} from "../../store/category/CategorySelectors";
import {
  LoadChildCategories,
  resetCategoryExpandState,
  resetTopCategoriesLoadingState,
  setCurrentCategory,
} from "../../store/category/CategoryActions";
import {
  // loadProductsFromCategory,
  loadProductIdsFromCategory,
} from "../../store/product-list/ProductListActions";
import { resetProducts } from "../../store/product/ProductActions";
import { selectProductSequenceMode } from "../../store/product-list/ProductListSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { ConfirmCategorySelectionModalId } from "./ConfirmCategorySelection";
import {
  getCategoryTooltip,
  isCategoryPublishedSFCC,
  getCategoryNameSFCC,
} from "../../utils/CategoryUtils";
import {
  selectCurrentStoreId,
  selectCurrentLocale,
  selectStoreListIds,
  selectCurrencyCode,
} from "../../store/store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { useQuery } from "../../hooks/useQueryParams";
import {
  selectCallerAccountId,
  selectConfigValue,
} from "../../store/app-config/AppConfigSelectors";
import AppState from "../../store/AppState";
import TreeViewComponent, { TreeNode } from "../tree-view/TreeView";
import Tooltip from "../common/ToolTip";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    overflowY: "auto",
    flexGrow: 1,
    margin: theme.spacing(1),
  },
  rootLabel: {
    "&:focus>.MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent !important",
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#78a6be",
    },
  },
  count: {
    fontSize: ".8rem",
    marginLeft: "5px",
    marginBottom: "1px",
  },
  progress: {
    marginLeft: "5px",
  },
  skeletonLabel: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
  },
}));

interface Props {
  selected: string[];
  checked: boolean;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

const CategorySelection: React.FC<Props> = (props) => {
  const { selected, setSelected } = props;
  const [expanded, setExpanded] = useState<string[]>([]);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const query = useQuery();
  const catalogIdQuery = query.get("catalogId") ?? "";
  const storeIdQuery = query.get("storeId") ?? "";
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const localeCodeQuery = query.get("localeCode") ?? "";
  const localeCodeQueryStr =
    localeCodeQuery !== "" ? `&localeCode=${localeCodeQuery}` : "";
  const dispatch = useDispatch();

  const childCategories = useSelector(selectChildCategories);
  const sequenceMode = useSelector(selectProductSequenceMode);
  const categoryTree = useSelector(selectCategories);
  const isInReportsTab = url.includes("reports");
  const currentStoreId = useSelector(selectCurrentStoreId) ?? "";
  const currentCatalogId = useSelector(selectCurrentCatalogId);
  const currentLocaleId = useSelector(selectCurrentLocale) ?? "";
  const isTopCategoriesFetched = useSelector(selectTopCategoriesFetched);
  const topCategories = useSelector(selectTopCategories);
  const storeListIds = useSelector(selectStoreListIds);
  const accountId = useSelector(selectCallerAccountId);
  const currencyCode = useSelector(selectCurrencyCode);

  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );
  const isCatalogOrStoreChanged = useSelector(selectIsCatalogOrStoreChanged);
  const userAccountId = useSelector(selectCallerAccountId);

  useEffect(() => {
    if (isCatalogOrStoreChanged) {
      setExpanded([]);
      dispatch(resetCategoryExpandState());
    }
    if (topCategories.length) {
      dispatch(resetTopCategoriesLoadingState());
    }
  }, [topCategories, dispatch, isCatalogOrStoreChanged]);

  const handleToggle = useCallback(
    (_event: object, node: Category | TreeNode) => {
      if (currentCatalogId) {
        const categoryId = node.id;
        if (!childCategories[categoryId]) {
          dispatch(
            LoadChildCategories(
              currentCatalogId,
              categoryId,
              currentLocaleId,
              currentStoreId,
            ),
          );
        }
      }
    },
    [
      childCategories,
      currentCatalogId,
      currentLocaleId,
      currentStoreId,
      dispatch,
    ],
  );
  const handleCategorySelection = useCallback(
    (categoryId: string) => {
      let payload = {
        categoryId,
        catalogId: currentCatalogId,
        localeCode: currentLocaleId,
        storeId: currentStoreId,
        storeListIds,
      };

      dispatch(resetProducts());
      dispatch(loadProductIdsFromCategory(payload)); // TODO this action needs to be renamed back to loadProductsFromCategory...  loading ids first is a loading strategy.  This component should not be concerned about the loading strategy.
      //dispatch(loadProductsFromCategory(payload));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentCatalogId,
      currentLocaleId,
      currentStoreId,
      dispatch,
      storeListIds,
      accountId,
      currencyCode,
    ],
  );

  useEffect(
    () => {
      if (
        categoryPathQuery !== "" &&
        currentStoreId !== "" &&
        currentCatalogId !== ""
      ) {
        const categoryTreeQuery = categoryPathQuery.split(";");
        const selectedCategory =
          categoryTreeQuery[categoryTreeQuery.length - 1];
        if (
          selectedCategory &&
          selected.length &&
          selected[0] !== selectedCategory
        ) {
          const categoryPath = categoryTreeQuery
            ? `${categoryTreeQuery?.join(";")};${selected[0]}`
            : `${selected[0]}`;
          handleCategorySelection(selected[0]);
          setSelected(selected);
          dispatch(setCurrentCategory(selected[0]));
          categoryTreeQuery.pop();
          setExpanded(categoryTreeQuery);
          history.replace(
            `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}${localeCodeQueryStr}&catalogId=${catalogIdQuery}&categoryPath=${categoryPath}`,
          );
        } else if (selected.length === 0) {
          handleCategorySelection(selectedCategory);
          setSelected([selectedCategory]);
          dispatch(setCurrentCategory(selectedCategory));
          categoryTreeQuery.pop();
          setExpanded(categoryTreeQuery);
        }
      } else {
        if (selected.length > 0) {
          setSelected([]);
        }
        if (
          (currentStoreId === "" || currentCatalogId === "") &&
          expanded.length > 0
        ) {
          setExpanded([]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      categoryPathQuery,
      currentStoreId,
      currentCatalogId,
      selected,
      expanded,
      handleToggle,
      handleCategorySelection,
      setSelected,
      dispatch,
    ],
  );

  useEffect(() => {
    const categoryTreeQuery = categoryPathQuery.split(";");
    let CategoriesObj = categoryTree;
    const categoryPath: string[] = [];
    const newPath = categoryPath.join(";");
    categoryTreeQuery.forEach((id) => {
      if (CategoriesObj?.length === 0) return;

      const foundIndex = CategoriesObj.findIndex(
        (category) => category.id === id,
      );

      if (foundIndex !== -1) {
        categoryPath.push(id);
        CategoriesObj = CategoriesObj[foundIndex].children ?? [];
      } else if (newPath) {
        history.replace(
          `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}${localeCodeQueryStr}&catalogId=${catalogIdQuery}&categoryPath=${newPath}`,
        );

        return;
      }
    });
  }, [
    localeCodeQueryStr,
    catalogIdQuery,
    categoryPathQuery,
    categoryTree,
    history,
    storeIdQuery,
    url,
    userAccountId,
  ]);

  const renderLabel = useCallback(
    (node: Category | TreeNode) => (
      <Tooltip tooltipTitle={getCategoryTooltip(node)} placement="right">
        <Typography variant="sidebarTreeTitle" noWrap>
          {isCategoryPublishedSFCC(node as Category) ? "" : "(U) "}
          {getCategoryNameSFCC(
            node as Category,
            currentLocaleId,
            defaultLocaleId,
          )}
          {node ? (
            <span className={classes.count}>
              {node?.productCount ? `(${node.productCount.toString()})` : ""}
            </span>
          ) : (
            <CircularProgress size={15} className={classes.progress} />
          )}
        </Typography>
      </Tooltip>
    ),
    [classes.count, classes.progress, currentLocaleId, defaultLocaleId],
  );
  const labelClickHandler = useCallback(
    (node: Category | TreeNode, e: React.MouseEvent) => {
      e.preventDefault();
      if (!isInReportsTab && sequenceMode) {
        dispatch(
          setModalState(ConfirmCategorySelectionModalId, true, {
            categoryId: node.id,
            categoryPath: node.categoryPath,
          }),
        );
      } else {
        handleCategorySelection(node.id);
        setSelected([node.id]);
        dispatch(setCurrentCategory(node.id));
        const categoryPath = node.categoryPath
          ? `${node.categoryIds?.join(";")};${node.id}`
          : `${node.id}`;

        history.push(
          `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}${localeCodeQueryStr}&catalogId=${catalogIdQuery}&categoryPath=${categoryPath}`,
        );
      }
    },
    [
      localeCodeQueryStr,
      catalogIdQuery,
      dispatch,
      sequenceMode,
      history,
      setSelected,
      storeIdQuery,
      url,
      handleCategorySelection,
      isInReportsTab,
      userAccountId,
    ],
  );

  return storeIdQuery !== "" ? (
    <TreeViewComponent
      nodes={categoryTree}
      selectedNodes={selected}
      handleToggle={handleToggle}
      labelClickHandler={labelClickHandler}
      renderLabel={renderLabel}
      showInitialLoading={categoryTree.length === 0 && !isTopCategoriesFetched}
      expanded={expanded}
    />
  ) : null;
};

export default CategorySelection;
