import { call, put, select, takeEvery } from "typed-redux-saga/macro";
import { callApi } from "../../utils/SagaUtils";
import {
  FETCH_PRODUCT_BADGES_LIST,
  LOAD_PRODUCT_BADGES_LIST,
  QUERY_PRODUCT_BADGE,
} from "./ProductBadgeTypes";
import { getData, postData } from "../../services/ApiService";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store-list/StoreListSelectors";
import { LoadCatalogListAction } from "store/catalog/CatalogTypes";
import {
  QUERY_PRODUCT_BADGE_URL,
  LoadProductBadgeAction,
} from "./ProductBadgeTypes";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";

export function* watchLoadProductBadgeList() {
  yield* takeEvery(LOAD_PRODUCT_BADGES_LIST, loadProductBadgeList);
}

function* loadProductBadgeList(action: LoadCatalogListAction) {
  const actionType = FETCH_PRODUCT_BADGES_LIST;
  const storeId = action.payload.storeId;
  const localeCode = yield* select(selectCurrentLocale);

  const constName = Object.keys({ FETCH_PRODUCT_BADGES_LIST })[0].toString();
  const headersObj = {
    "x-store-id": storeId || "my-store",
    "x-locale-code": localeCode || "default",
    "x-currency-code": "USD",
  };

  try {
    const endpoint = acquireEndpoint(constName);
    yield* call(callApi, actionType, getData, null, endpoint, headersObj);
  } catch (e: any) {
    console.error(e);
    yield* put({ type: FETCH_PRODUCT_BADGES_LIST.FAILURE, message: e.message });
  }
}

function* fetchProductQueryProduct(action: LoadProductBadgeAction) {
  try {
    const actionType = QUERY_PRODUCT_BADGE;
    const storeId = yield* select(selectCurrentStoreId);
    const catalogId = yield* select(selectCurrentCatalogId);
    const localeCode = yield* select(selectCurrentLocale);
    const constName = Object.keys({ QUERY_PRODUCT_BADGE_URL })[0].toString();
    const endpoint = acquireEndpoint(constName);
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };

    yield* call(
      callApi,
      actionType,
      postData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield* put({ type: QUERY_PRODUCT_BADGE.FAILURE, message: e.message });
  }
}

export function* watchLoadQueryProduct() {
  yield* takeEvery(QUERY_PRODUCT_BADGE.REQUEST, fetchProductQueryProduct);
}
