import { GraphQLVariables, SyncLogInserts } from "../../services/GraphQLTypes";
import {
  FETCH_SORTED_PRODUCTS,
  ProductSequenceData,
} from "../product-list/ProductListTypes";

/**
 * Action types
 */
export const LOAD_PRODUCT_DETAILS = "LOAD_PRODUCT_DETAILS";
export const LOAD_PRODUCT_DETAILS_FOR_REPORT =
  "LOAD_PRODUCT_DETAILS_FOR_REPORT";
export const UPDATE_CHILDREN_ITEMS = "UPDATE_CHILDREN_ITEMS";
export const LOAD_PRODUCT_INVENTORIES = "LOAD_PRODUCT_INVENTORIES";
export const LOAD_PRODUCT_PRICE = "LOAD_PRODUCT_PRICE";
export const LOAD_PRODUCT_ANALYTICS = "LOAD_PRODUCT_ANALYTICS";
export const LOAD_CATEGORY_BY_PRODUCT_ID = "LOAD_CATEGORY_BY_PRODUCT_ID";
export const CHANGE_PRODUCT_PUBLISH_STATE = "CHANGE_PRODUCT_PUBLISH_STATE";
export const LOAD_ANALYTICS_SERVICE_URL = "LOAD_ANALYTICS_SERVICE_URL";
export const CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE =
  "CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE";
export const CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE =
  "CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE";
export const CHANGE_PRODUCT_PUBLISH_STATE_SFCC =
  "CHANGE_PRODUCT_PUBLISH_STATE_SFCC";
export const CHANGE_PRODUCT_DELETE_STATE = "CHANGE_PRODUCT_DELETE_STATE";
export const CHANGE_SINGLE_PRODUCT_DELETE_STATE =
  "CHANGE_SINGLE_PRODUCT_DELETE_STATE";
export const CHANGE_PRODUCT_COLORS = "CHANGE_PRODUCT_COLORS";
export const CHANGE_PRODUCT_ATTRIBUTE = "CHANGE_PRODUCT_ATTRIBUTE";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const UPDATE_PRODUCT_LIST_BY_SEARCH = "UPDATE_PRODUCT_LIST_BY_SEARCH";
export const UPDATE_RECENTLY_ADDED_PRODUCTS_AFTER_SAVE =
  "UPDATE_RECENTLY_ADDED_PRODUCTS_AFTER_SAVE";
export const FETCH_CATEGORY_BY_PRODUCT_ID = {
  REQUEST: "FETCH_CATEGORY_BY_PRODUCT_ID_REQUEST",
  SUCCESS: "FETCH_CATEGORY_BY_PRODUCT_ID_SUCCESS",
  FAILURE: "FETCH_CATEGORY_BY_PRODUCT_ID_FAILURE",
};
export const UPDATE_PRODUCT_DELETE_STATE = {
  REQUEST: "UPDATE_PRODUCTS_DELETE_STATE_REQUEST",
  SUCCESS: "UPDATE_PRODUCTS_DELETE_STATE_SUCCESS",
  FAILURE: "UPDATE_PRODUCTS_DELETE_STATE_FAILURE",
};
export const UPDATE_SINGLE_PRODUCT_DELETE_STATE = {
  REQUEST: "UPDATE_SINGLE_PRODUCTS_DELETE_STATE_REQUEST",
  SUCCESS: "UPDATE_SINGLE_PRODUCTS_DELETE_STATE_SUCCESS",
  FAILURE: "UPDATE_SINGLE_PRODUCTS_DELETE_STATE_FAILURE",
};
export const UPDATE_PRODUCT_PUBLISH_STATE = {
  REQUEST: "UPDATE_PRODUCTS_PUBLISH_STATE_REQUEST",
  SUCCESS: "UPDATE_PRODUCTS_PUBLISH_STATE_SUCCESS",
  FAILURE: "UPDATE_PRODUCTS_PUBLISH_STATE_FAILURE",
};
export const UPDATE_PRODUCT_PUBLISH_BY_DEFAULT_STATE = {
  REQUEST: "UPDATE_PRODUCT_PUBLISH_BY_DEFAULT_STATE_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_PUBLISH_BY_DEFAULT_STATE_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_PUBLISH_BY_DEFAULT_STATE_FAILURE",
};
export const UPDATE_PRODUCT_PUBLISH_STATE_BY_STORE = {
  REQUEST: "UPDATE_PRODUCT_PUBLISH_STATE_BY_STORE_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_PUBLISH_STATE_BY_STORE_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_PUBLISH_STATE_BY_STORE_FAILURE",
};
export const UPDATE_PRODUCT_PUBLISH_STATE_SFCC = {
  REQUEST: "UPDATE_PRODUCTS_PUBLISH_STATE_SFCC_REQUEST",
  SUCCESS: "UPDATE_PRODUCTS_PUBLISH_STATE_SFCC_SUCCESS",
  FAILURE: "UPDATE_PRODUCTS_PUBLISH_STATE_SFCC_FAILURE",
};
export const FETCH_PRODUCT_DETAILS = {
  REQUEST: "FETCH_PRODUCT_DETAILS_REQUEST",
  SUCCESS: "FETCH_PRODUCT_DETAILS_SUCCESS",
  FAILURE: "FETCH_PRODUCT_DETAILS_FAILURE",
};
export const FETCH_PRODUCT_DETAILS_FOR_REPORT = {
  REQUEST: "FETCH_PRODUCT_DETAILS_FOR_REPORT_REQUEST",
  SUCCESS: "FETCH_PRODUCT_DETAILS_FOR_REPORT_SUCCESS",
  FAILURE: "FETCH_PRODUCT_DETAILS_FOR_REPORT_FAILURE",
};
export const FETCH_PRODUCT_INVENTORIES = {
  REQUEST: "FETCH_PRODUCT_INVENTORIES_REQUEST",
  SUCCESS: "FETCH_PRODUCT_INVENTORIES_SUCCESS",
  FAILURE: "FETCH_PRODUCT_INVENTORIES_FAILURE",
};
export const FETCH_PRODUCT_PRICE = {
  REQUEST: "FETCH_PRODUCT_PRICE_REQUEST",
  SUCCESS: "FETCH_PRODUCT_PRICE_SUCCESS",
  FAILURE: "FETCH_PRODUCT_PRICE_FAILURE",
};
export const UPDATE_PRODUCT_TO_PRODUCT_SEQUENCE = {
  REQUEST: "UPDATE_PRODUCT_TO_PRODUCT_SEQUENCE_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_TO_PRODUCT_SEQUENCE_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_TO_PRODUCT_SEQUENCE_FAILURE",
};
export const UPDATE_ATTR_VALUE_SEQUENCE = {
  REQUEST: "UPDATE_ATTR_VALUE_SEQUENCE_REQUEST",
  SUCCESS: "UPDATE_ATTR_VALUE_SEQUENCE_SUCCESS",
  FAILURE: "UPDATE_ATTR_VALUE_SEQUENCE_FAILURE",
};
export const UPDATE_ATTR_VALUE_DESCRIPTION_SEQUENCE = {
  REQUEST: "UPDATE_ATTR_VALUE_DESCRIPTION_SEQUENCE_REQUEST",
  SUCCESS: "UPDATE_ATTR_VALUE_DESCRIPTION_SEQUENCE_SUCCESS",
  FAILURE: "UPDATE_ATTR_VALUE_DESCRIPTION_SEQUENCE_FAILURE",
};
export const UPDATE_LOCALE_PRODUCT_PUBLISH = {
  REQUEST: "UPDATE_LOCALE_PRODUCT_PUBLISH_REQUEST",
  SUCCESS: "UPDATE_LOCALE_PRODUCT_PUBLISH_SUCCESS",
  FAILURE: "UPDATE_LOCALE_PRODUCT_PUBLISH_FAILURE",
};
export const UPDATE_PRODUCT_ATTRIBUTE = {
  REQUEST: "UPDATE_PRODUCT_ATTRIBUTE_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_ATTRIBUTE_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_ATTRIBUTE_FAILURE",
};
export const UPDATE_LEADING_COLOR_BY_CATEGORY = {
  REQUEST: "UPDATE_LEADING_COLOR_BY_CATEGORY_REQUEST",
  SUCCESS: "UPDATE_LEADING_COLOR_BY_CATEGORY_SUCCESS",
  FAILURE: "UPDATE_LEADING_COLOR_BY_CATEGORY_FAILURE",
};
export const FETCH_PRODUCT_ANALYTICS = {
  REQUEST: "FETCH_PRODUCT_ANALYTICS_REQUEST",
  SUCCESS: "FETCH_PRODUCT_ANALYTICS_SUCCESS",
  FAILURE: "FETCH_PRODUCT_ANALYTICS_FAILURE",
};
export const CHECK_VALIDATION_PRODUCT_CODES = {
  REQUEST: "CHECK_VALIDATION_PRODUCT_CODES_REQUEST",
  SUCCESS: "CHECK_VALIDATION_PRODUCT_CODES_SUCCESS",
  FAILURE: "CHECK_VALIDATION_PRODUCT_CODES_FAILURE",
};

export const UPDATE_PRODUCT_ISPUBLISHED_STATE = {
  REQUEST: "UPDATE_PRODUCTS_PUBLISH_STATE_REQUEST",
  SUCCESS: "UPDATE_PRODUCTS_PUBLISH_STATE_SUCCESS",
  FAILURE: "UPDATE_PRODUCTS_PUBLISH_STATE_FAILURE",
  PENDING: "UPDATE_PRODUCTS_PUBLISH_STATE_PENDING",
};

export const UPDATE_PRODUCT_ISPUBLISHED = "UPDATE_PRODUCT_ISPUBLISHED";

export const RESET_UPDATE_PRODUCT_ISPUBLISHED_STATE =
  "RESET_UPDATE_PRODUCT_ISPUBLISHED_STATE";
export const RESET_UPDATE_PRODUCT_ISPUBLISHED =
  "RESET_UPDATE_PRODUCT_ISPUBLISHED";

export const RESET_PRODUCT_DETAILS = "RESET_PRODUCT_DETAILS";
export const UPDATED_SELECTED_SWATCHES = "UPDATED_SELECTED_SWATCHES";
export const UPDATE_SELECTED_PRODUCTS_CODE = "UPDATE_SELECTED_PRODUCTS_CODE";

export const CHANGE_LOCALE_PRODUCT_PUBLISH = "CHANGE_LOCALE_PRODUCT_PUBLISH";
export const GET_VALIDATION_PRODUCT_CODES = "GET_VALIDATION_PRODUCT_CODES";
export const RESET_PRODUCTS = "RESET_PRODUCTS";

// export const RESET_TEMPORARY_DATA = "RESET_TEMPORARY_DATA";
export const ADD_MIGRATED_CATEGORIES = "ADD_MIGRATED_CATEGORIES";
export const ADD_MIGRATED_CATEGORIES_DATA = "ADD_MIGRATED_CATEGORIES_DATA";
export const ADD_MIGRATED_CATEGORIES_NEW = "ADD_MIGRATED_CATEGORIES_NEW";
export const LOAD_CLIP_CODES = "LOAD_CLIP_CODES";
export const ADD_MIGRATED_CATEGORIES_NEW_DATA =
  "ADD_MIGRATED_CATEGORIES_NEW_DATA";

export const ADD_PRODUCT_IN_CATEGORY = {
  REQUEST: "ADD_PRODUCT_IN_Category_REQUEST",
  SUCCESS: "ADD_PRODUCT_IN_Category_SUCCESS",
  FAILURE: "ADD_PRODUCT_IN_Category_FAILURE",
};

export const REMOVE_PRODUCT_IN_CATEGORY = {
  REQUEST: "REMOVE_PRODUCT_IN_Category_REQUEST",
  SUCCESS: "REMOVE_PRODUCT_IN_Category_SUCCESS",
  FAILURE: "REMOVE_PRODUCT_IN_Category_FAILURE",
};

export const UPDATE_PUBLISH_FLAG = {
  REQUEST: "UPDATE_PUBLISH_FLAG_REQUEST",
  SUCCESS: "UPDATE_PUBLISH_FLAG_SUCCESS",
  FAILURE: "UPDATE_PUBLISH_FLAG_FAILURE",
};

export type ProductActionTypes =
  | LoadProductDetailsResponseAction
  | LoadCategoryByProductIdResponseAction
  | LoadProductAnalyticsResponseAction
  | UpdatedSelectedSwatchesAction
  | UpdateSelectedProductsCodeAction
  | UpdateChildrenItemsAction
  | SetSearchTermAction
  | UpdateProductListBySearchAction
  | SetDatePickerRangeAction
  | UpdateProductColorsAction;

/**
 * Load Product types
 */
export interface UpdateProductPublishFlagPayload {
  isPublished?: boolean | null;
  productIds?: string[];
  storeState?: {
    storeId: string;
    value: StoreValue;
  }[];
  parentId?: string | null;
}
export interface UpdateProductPublishFlagAction {
  payload: UpdateProductPublishFlagPayload;
  type: typeof UPDATE_PRODUCT_ISPUBLISHED;
}

export interface LoadProductDetailsAction {
  payload: GetProductDetailsPayload;
  type: typeof LOAD_PRODUCT_DETAILS;
}

export interface LoadProductDetailsForReportAction {
  payload: GetProductDetailsPayload;
  type: typeof LOAD_PRODUCT_DETAILS_FOR_REPORT;
}

export interface LoadProductDetailsResponseAction {
  payload: GetProductDetailsResponsePayload;
  type: typeof FETCH_PRODUCT_DETAILS.SUCCESS;
}

export interface LoadProductDetailsForReportResponseAction {
  payload: GetProductDetailsResponsePayload;
  type: typeof FETCH_PRODUCT_DETAILS_FOR_REPORT.SUCCESS;
}
export interface UpdatedSelectedSwatchesAction {
  payload: UpdatedSelectedSwatchesPayload;
  type: typeof UPDATED_SELECTED_SWATCHES;
}

export interface ProductData {
  productId: string;
  code: string;
  typeCode: string;
  name: string;
  thumbnail: string;
  isPublished: boolean;
  url: string;
  stock: number;
  stockValue: number;
  listPrice: number;
  translations: { localeCode: string }[];
}
export interface RecentlyAddedProductsData {
  [categoryId: string]: ProductData[];
}

export type CategoryDataSet = Record<string, ProductData[]>;

export interface GetProductDetailsPayload {
  catalogId?: string;
  categoryId?: string;
  productId?: string;
  searchTerm?: string;
  localeId?: string;
  storeId?: string;
}

export interface SelectedSwatch {
  selectedSwatch: string;
  productImage: string;
}
export interface UpdatedSelectedSwatchesPayload {
  swatch: SelectedSwatch;
  productId: string;
}
export interface UpdateProductsAffectedRowsPayload {
  AffectedRows: number | null;
}

export interface GetProductDetailsResponsePayload {
  count: number;
  results: ProductDetailsData[];
  total: number;
  storeId: string;
}

/**
 * Load children item types
 */
export interface UpdateChildrenItemsAction {
  payload: UpdateChildrenItemsPayload;
  type: typeof UPDATE_CHILDREN_ITEMS;
}

export interface UpdateChildrenItemsPayload {
  parentId: string;
  toChildProducts: ToChildProduct[];
}

/**
 * Update product colors
 */
export interface ProductSequence {
  productId: string;
  sequence: number;
  type: string;
}

export interface AttrValueSequence {
  attributeValueId: string;
  sequence: number;
}

export interface UpdateProductColorsPayload {
  productSequences: ProductSequence[];
  attrValueSequences: AttrValueSequence[];
  storeId: string;
  catalogId: string;
  categoryId: string;
  productId: string;
  type: number;
  leadingColor: string;
  publishedProductIds: string[];
  unpublishedProductIds: string[];
  localeCodes: string[];
}

export interface UpdateProductColorsAction {
  payload: UpdateProductColorsPayload;
  type: typeof CHANGE_PRODUCT_COLORS;
}

export type UpdateProductColorsResponse =
  | UpdateProductToProductSequenceResponse
  | UpdateAttrValueSequenceResponse
  | UpdateLeadingColorByCategoryResponse
  | UpdateProductPublishStateResponse
  | UpdateLocaleProductPublishResponse;

/**
 * Update product to product sequence types
 */
export interface UpdateProductToProductSequenceResponse {
  type: string;
  payload: UpdateProductToProductSequenceResponsePayload;
  error: string;
}

export interface UpdateProductToProductSequenceResponsePayload {
  data: UpdateProductToProductSequence;
  variables: GraphQLVariables;
}

export interface UpdateProductToProductSequence {
  insert_SyncLog: AffectedRows;
  [name: string]: AffectedRows;
}

/**
 * Update attribute value sequence types
 */
export interface UpdateAttrValueSequenceResponse {
  type: string;
  payload: UpdateAttrValueSequenceResponsePayload;
  error: string;
}

export interface UpdateAttrValueSequenceResponsePayload {
  data: UpdateAttrValueSequence;
  variables: GraphQLVariables;
}

export interface UpdateAttrValueSequence {
  insert_SyncLog: AffectedRows;
  [name: string]: AffectedRows;
}

/**
 * Update leading color by category
 */
export interface UpdateLeadingColorByCategoryResponse {
  type: string;
  payload: UpdateLeadingColorByCategoryResponsePayload;
  error: string;
}

export interface UpdateLeadingColorByCategoryResponsePayload {
  data: UpdateLeadingColorData;
  variables: GraphQLVariables;
}

export interface UpdateLeadingColorData {
  insert_ProductLeadingColor?: AffectedRows;
  delete_ProductLeadingColor_by_pk?: UpdatedAt;
  update_ProductLeadingColor_by_pk?: UpdatedAt;
  insert_SyncLog: AffectedRows;
}

export interface UpdatedAt {
  updatedAt: string;
}

export interface UpdateLocaleProductPublishPayload {
  publishedProductIds: string[];
  unpublishedProductIds: string[];
  localeCodes: string[];
}
export interface CheckValidationProductCodesPayload {
  ProductCodes: string[];
}

export interface UpdateProductAttributePayload {
  newAttributeValueId: string;
  oldAttributeValueId: string;
  productId: string;
  type: number;
  isStoreSpecific: boolean;
  isLocalized: boolean;
  storeId: string;
  localeCode: string;
}

export interface UpdateProductAttributeAction {
  payload: UpdateProductAttributePayload;
  type: typeof CHANGE_PRODUCT_ATTRIBUTE;
}

export interface UpdateProductAttributeResponse {
  type: string;
  payload: UpdateProductAttributeResponsePayload;
  error: string;
}

export interface UpdateProductAttributeResponsePayload {
  data: UpdateProductAttributeData;
  variables: GraphQLVariables;
}

export interface UpdateProductAttributeData {
  insert_ProductAttributeValue?: AffectedRows;
  delete_ProductAttributeValue?: AffectedRows;
  insert_SyncLog: AffectedRows;
}

export interface UpdateLocaleProductPublishAction {
  payload: UpdateLocaleProductPublishPayload;
  type: typeof CHANGE_LOCALE_PRODUCT_PUBLISH;
}

export interface CheckValidationProductCodesAction {
  payload: CheckValidationProductCodesPayload;
  type: typeof GET_VALIDATION_PRODUCT_CODES;
}

export interface UpdateProductPublishStatePayload {
  publishedProductIds: string[];
  unpublishedProductIds: string[];
}
export interface UpdateProductPublishByDefaultStatePayload {
  parentId: string;
  childId: string;
  isPublishedByDefault: boolean;
}
export interface UpdateProductPublishStateByStorePayload {
  parentId: string;
  childId: string;
  storeId: string;
  isPublishedByStore: boolean | null;
}

export interface UpdateProductPublishStateAction {
  payload: UpdateProductPublishStatePayload;
  type: typeof CHANGE_PRODUCT_PUBLISH_STATE;
}
export interface UpdateProductPublishByDefaultStateAction {
  payload: UpdateProductPublishByDefaultStatePayload;
  type: typeof CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE;
}
export interface UpdateProductPublishStateByStoreAction {
  payload: UpdateProductPublishStateByStorePayload;
  type: typeof CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE;
}

export interface UpdateProductDeleteStatePayload {
  productIds: string[];
  isDeleted: boolean;
}
export interface UpdateSingleProductDeleteStatePayload {
  parentId: string;
  childId: string;
  isDeleted: boolean;
}

export interface UpdateProductDeleteStateAction {
  payload: UpdateProductDeleteStatePayload;
  type: typeof CHANGE_PRODUCT_DELETE_STATE;
}

export interface UpdateSingleProductDeleteStateAction {
  payload: UpdateSingleProductDeleteStatePayload;
  type: typeof CHANGE_SINGLE_PRODUCT_DELETE_STATE;
}

export interface UpdateSelectedProductsCodePayload {
  selectedProductsCode: string[];
}
export interface UpdateSelectedProductsCodeAction {
  payload: UpdateSelectedProductsCodePayload;
  type: typeof UPDATE_SELECTED_PRODUCTS_CODE;
}

/**
 * Fetch CategoryToProduct product types
 */

// new
export interface ColorData {
  sequence: string;
  name: string;
  swatchImage: string;
  thumbnail: string;
  code: string;
  colorId: string;
  isPublished: boolean;
  translations: [
    {
      localeCode: string;
      name: string;
    },
  ];
  stock: number;
  price: number;
  lowPrice?: number;
  highPrice?: number;
  storeSpecific: { storeId: string; isPublished: boolean | null }[];
}

//new
export interface ProductInfoData {
  productId: string;
  code: string;
  typeCode: string;
  name: string;
  thumbnail: string;
  isPublished: boolean;
  variationBaseId: string;
  hasColors: boolean;
  hasManageableColors: boolean;
  hasSequenceableColors: boolean;
  colorVariationAttributeId?: string;
  url: string;
  stock: number;
  stockValue: number;
  listPrice: number;
  lowPrice: number;
  highPrice: number;
  colors: ColorData[];
  translations: [
    {
      localeCode: string;
      name: string;
      isPublished: string;
      thumbnail: string;
      url: string;
    },
  ];
  storeSpecific?:
    | { storeId: string; isPublished: boolean | null }[]
    | undefined;
  categorySpecific: categorySpecificData[];
  imageGroups?: ProductImageGroups[];
}

// old

// export interface ProductSubDetailsData {
//   productId: string;
//   code: string;
//   typeCode: string;
//   name: string;
//   thumbnail: string;
//   isPublished: boolean;
//   url: string;
//   stock: number;
//   stockValue: number;
//   listPrice: number;
//   translations: [
//     {
//       localeCode: string;
//     }
//   ];
// }

// new
export interface ProductSubDetailsData {
  cachedAt: Date;
  product: ProductInfoData;
}

// old

// export interface ProductDetailsData {
//   product: ProductSubDetailsData;
//   sequence: number;
//   // id: string;
//   // code: string;
//   // name: string;
//   // thumbnail: string;
//   // position: number;
//   // isPublished: boolean;
//   // typeCode: string;
//   // updatedAt: string;
//   // translations: [
//   //   {
//   //     localeCode: string;
//   //     name: string;
//   //   }
//   // ];
// }

// new
export interface ProductDetailsData {
  cachedProduct: ProductSubDetailsData;
  sequence: number;
  productId: string;
  // id: string;
  // code: string;
  // name: string;
  // thumbnail: string;
  // position: number;
  // isPublished: boolean;
  // typeCode: string;
  // updatedAt: string;
  // translations: [
  //   {
  //     localeCode: string;
  //     name: string;
  //   }
  // ];
}

export interface ProductObject {
  product: Product;
}

export interface ProductAttributeObject {
  product: ProductAttribute;
}

export interface Product {
  //overrides: any;
  thumbnail?: string;
  name: string;
  //descriptions?: any;
  productId: string;
  code: string;
  price?: number;
  lowPrice?: number;
  highPrice?: number;
  typeCode: string;
  descriptions?: ProductDescription[];
  //updatedAt: string;
  isPublished: boolean;
  variationBaseId?: string;
  hasColors: boolean;
  hasManageableColors?: boolean;
  hasSequenceableColors?: boolean;
  colorVariationAttributeId?: string;
  colors: ColorData[];
  overrides?: ProductOverride[];
  inventories?: Inventory[];
  toChildProducts?: ToChildProduct[];
  productScore?: number;
  stock: number;
  stockValue: number;
  listPrice: number;
  cachedAt?: Date;
  isRefreshing?: boolean;
  storeSpecific?:
    | { storeId: string; isPublished: boolean | null }[]
    | undefined;
  isUpdatingPublishFlag?: boolean;
  translations: any;
  categorySpecific: categorySpecificData[];
  imageGroups?: ProductImageGroups[];
}

export interface ProductImageGroups {
  images: {
    imageId: string;
    thumbnail: string;
  }[];
  variationAttributes: {
    attributeId: string;
    values: {
      value: string;
    }[];
  }[];
}

export interface ProductAttribute {
  productId: string;
  code: string;
  attributeValues: attributeValue[];
  attributeValueOverrides: attributeValue[];
  attributeLocalizedValues: attributeValue[];
}

export interface ProductDescription {
  isPublished: boolean;
  name: string;
  thumbnail: string;
  localeCode: string;
}

/**
 * Product to child product types
 */
export interface ChildrenItemsData {
  Product: ChildProductNode[];
}

export interface ChildProductNode {
  productId: string;
  prices: Price[];
  inventories: Inventory[];
  toChildProducts: ToChildProduct[];
  leadingColors: LeadingColor[];
}

export interface Price {
  amount: number;
  currencyCode: string;
}

export interface Inventory {
  quantity: number;
}

export interface LeadingColor {
  attributeValueId: string;
  catalogId: string;
  categoryId: string;
  productId: string;
  storeId: string;
}

export interface ToChildProduct {
  childProduct: ChildProduct;
  childProductId: string;
  sequence: number;
  typeCode: string;
}

export interface ChildProduct {
  code: string;
  isColorGroup: boolean;
  isPublished: boolean;
  isDeleted: boolean;
  descriptions: ProductDescription[];
  attributeValues: ProductAttributeValue[];
  prices: Price[];
  inventories: Inventory[];
  overrides: ProductOverride[];
  storeSpecific?:
    | { storeId: string; isPublished: boolean | null }[]
    | undefined;
}

export interface ProductOverride {
  isPublished: boolean | null;
  storeId: string;
}

export interface ProductAttributeValue {
  attributeValueId: string;
  productId: string;
  sequence: number;
  attributeValue: AttributeValue;
}

export interface AttributeValue {
  code: string;
  descriptions: AttributeDescription[];
  attribute: Attribute;
  sequence: number;
  attributeValueId: string;
}

export interface AttributeDescription {
  displayValue: string;
  thumbnail?: string;
  sequence: number;
  localeCode: string;
}

export interface Attribute {
  isColor: boolean;
  code: string;
  attributeId: string;
  descriptions: {
    displayName: string;
    localeCode: string;
  }[];
}

export interface ProductSwatchType {
  attributeValueId: string;
  colorDescriptions: AttributeDescription[];
  childProductDescriptions: ProductDescription[];
  isPublished: boolean;
  attrValSequence: number;
  childProductSequence: number;
  childProductId: string;
}

export interface ProductPrice {
  lowPrice: number;
  highPrice: number;
  price: number;
  childProductPrices: ChildProductPrice[];
}

export interface ChildProductPrice {
  childProductId: string;
  price: number;
}

export interface ProductInventory {
  inventory: number;
  childProductInventories: ChildProductInventory[];
}

export interface Variants {
  variantId: string;
  stock: number;
  code: string;
  isPublished: boolean;
  variationOptions: variantTranslations[];
}

export interface variantTranslations {
  attributeCode: string;
  attributeLabel: string;
  valueCode: string;
  valueLabel: string;
  translations: {
    localeCode: string;
    valueLabel: string;
  }[];
}
export interface ProductVariants {
  results: Variants[];
  type: string;
}
export interface ChildProductInventory {
  childProductId: string;
  inventory: number;
}

export interface UpdateProductPublishStateResponse {
  type: string;
  payload: UpdateProductPublishStateResponsePayload;
  error: string;
}
export interface UpdateProductPublishByDefaultStateResponse {
  type: string;
  payload: UpdateProductPublishByDefaultStateResponsePayload;
  error: string;
}
export interface UpdateProductPublishStateByStoreResponse {
  type: string;
  payload: UpdateProductPublishStateByStoreResponsePayload;
  error: string;
}

export interface UpdateProductPublishStateResponsePayload {
  data: UpdateProductPublishData;
  variables: UpdateProductPublishStateVariables;
}
export interface UpdateProductPublishByDefaultStateResponsePayload {
  data: UpdateProduct;
  variables: UpdateProductPublishByDefaultStateVariables;
}
export interface UpdateProductPublishStateByStoreResponsePayload {
  data: InsertProductOverride;
  variables: UpdateProductPublishStateByStoreVariables;
}

export interface UpdateLocalProductPublishResponsePayload {
  data: UpdateProductPublishState;
  variables: UpdateLocaleProductPublishStateVariables;
}
export interface CheckValidationProductCodesResponsePayload {
  data: CheckValidationProductCodes;
  variables: CheckValidationProductCodesVariables;
}

export interface UpdateLocaleProductPublishResponse {
  type: string;
  payload: UpdateLocalProductPublishResponsePayload;
  error: string;
}
export interface CheckValidationProductCodesResponse {
  type: string;
  payload: CheckValidationProductCodesResponsePayload;
  error: string;
}

export interface UpdateProductPublishStateVariables {
  productIds: string[];
  isPublished: boolean;
}
export interface UpdateProductPublishByDefaultStateVariables {
  productId: string;
  isPublishedByDefault: boolean;
}
export interface UpdateProductPublishStateByStoreVariables {
  productId: string;
  storeId: string;
  isPublishedByStore: boolean;
}

export interface UpdateLocaleProductPublishStateVariables {
  publishedProductIds: string[];
  unpublishedProductIds: string[];
  localeCode: string[];
}
export interface CheckValidationProductCodesVariables {
  productCodes: string[];
}

export interface UpdateProductDeleteStateResponse {
  type: string;
  payload: UpdateProductDeleteStateResponsePayload;
  error: string;
}
export interface UpdateSingleProductDeleteStateResponse {
  type: string;
  payload: UpdateSingleProductDeleteStateResponsePayload;
  error: string;
}

export interface UpdateProductDeleteStateResponsePayload {
  data: UpdateProduct;
  variables: UpdateProductDeleteStateVariables;
}
export interface UpdateSingleProductDeleteStateResponsePayload {
  data: UpdateProduct;
  variables: UpdateSingleProductDeleteStateVariables;
}

export interface UpdateProductDeleteStateVariables {
  productIds: string[];
  isDeleted: boolean;
}
export interface UpdateSingleProductDeleteStateVariables {
  productId: string;
  isDeleted: boolean;
}

export interface UpdateProduct {
  update_Product: AffectedRows;
  insert_SyncLog: AffectedRows;
}
export interface InsertProductOverride {
  insert_ProductOverride: AffectedRows;
  insert_SyncLog: AffectedRows;
}

export interface ReturningProductPublishData {
  affected_rows: number;
  returning: {
    product: {
      code: string;
    };
  }[];
}

export interface UpdateProductPublishState {
  publish: ReturningProductPublishData;
  unpublish: ReturningProductPublishData;
}
export interface UpdateProductPublishData {
  publish: AffectedRows;
  unpublish: AffectedRows;
}

export interface AffectedRows {
  affected_rows: number;
}

export interface CheckValidationProductCodes {
  Product: { code: string }[];
}

export interface Item {
  SKU: string;
  [key: string]: string | number | boolean;
}

export interface ColorNode {
  [name: string]: ChildColor;
}

export interface ChildColor {
  id: string;
  name: string;
  productImage: string;
  itemIds: Items[];
  inventory: number;
  price: number | null;
  lowPrice: number | null;
  highPrice: number | null;
  sequence: number;
  isPublished: boolean;
  bounds?: ClientRect | DOMRect | null;
}

export interface Items {
  id: string;
  type: string;
}

export interface DragColor {
  draggedColor: ChildColor;
  color: ChildColor;
  colorDragStack: ChildColor;
  type: string;
  clientWidth: number;
  clientHeight: number;
}

/**
 * update publish state SFCC products types
 */
export type DefaultValue = "No Change" | "Publish" | "Unpublish";
export type StoreValue = DefaultValue | "Use Default";

export interface ProductOverrideSFCC {
  isPublished: boolean | null;
  readonly productId: string;
  readonly storeId: string;
}

// export interface UpdateProductPublishStateSFCCPayload {
//   productIds: string[];
//   defaultState: DefaultValue;
//   storeState: {
//     storeId: string;
//     value: StoreValue;
//   }[];
// }

// export interface UpdateProductPublishStateSFCCAction {
//   payload: UpdateProductPublishStateSFCCPayload;
//   type: typeof CHANGE_PRODUCT_PUBLISH_STATE_SFCC;
// }

export interface ReturningProductPublishDataSFCC {
  affected_rows: number;
  returning: {
    productId: string;
  }[];
}

export interface UpdateSFCCProduct {
  insert_ProductOverride?: ReturningProductPublishDataSFCC;
  update_Product?: ReturningProductPublishDataSFCC;
  SyncLogProduct?: AffectedRows;
  SyncLogProductOverride?: AffectedRows;
}

export interface UpdateProductPublishStateSFCCVariables {
  productIds: string[];
  productsOverride: {
    storeId: string;
    productId: string;
    isPublished: boolean;
  }[];
  syncLogProductOverrideInserts: SyncLogInserts[];
  syncLogInserts: SyncLogInserts[];
}

export interface UpdateProductPublishStateSFCCResponsePayload {
  data: UpdateSFCCProduct;
  variables: UpdateProductPublishStateSFCCVariables;
}

export interface UpdateProductPublishStateSFCCResponse {
  type: string;
  payload: UpdateProductPublishStateSFCCResponsePayload;
  error: string;
}

/**
 * item Management SFCC Table types
 */
export interface ItemManagementSFCCData {
  SKU: string;
  inventory: number;
  published: boolean;
  publishedStore: boolean | null;
  action: "delete";
  [key: string]: string | number | boolean | null;
}

/**
 * Fetch product analytics types
 */
export interface LoadProductAnalyticsPayload {
  storeId?: string;
  startDate: string;
  endDate: string;
  productCodes?: string[];
  partNumber?: string[];
  level?: string;
}

export interface LoadProductPayload {
  storeId?: string;
  localeId?: string;
  catalogId?: string;
  categoryId?: string;
  sequence?: string | number;
}

export interface LoadProductAnalyticsAction {
  payload: LoadProductAnalyticsPayload;
  type: typeof LOAD_PRODUCT_ANALYTICS;
}

export interface LoadProductAnalyticsResponse {
  type: string;
  payload: LoadProductAnalyticsResponsePayload;
  error: string;
}

export interface LoadProductAnalyticsResponsePayload {
  products: ProductAnalytics[];
}

export interface ProductAnalytics {
  analytics: AnalyticsData[];
  productCode: string;
}

export interface AnalyticsData {
  name: string;
  value: string | number;
}

export interface LoadProductAnalyticsResponseAction {
  payload: LoadProductAnalyticsResponsePayload;
  type: typeof FETCH_PRODUCT_ANALYTICS.SUCCESS;
}
export interface LoadCategoryByProductIdPayload {
  storeId?: string;
  catalogId?: string;
  productIds: string[];
  localeCode?: string;
  isFetchedBySearch?: boolean;
}

export interface LoadClipCodesPayload {
  clipCodes: string;
}
export interface LoadClipCodesAction {
  type: typeof LOAD_CLIP_CODES;
  payload: LoadClipCodesPayload;
}

export interface LoadCategoryByProductIdAction {
  type: typeof LOAD_CATEGORY_BY_PRODUCT_ID;
  payload: LoadCategoryByProductIdPayload;
}

export interface LoadCategoryByProductIdResponseAction {
  payload: LoadCategoryByProductIdResponsePayload;
  type: typeof FETCH_CATEGORY_BY_PRODUCT_ID.SUCCESS;
}

export interface CategoryType {
  categoryId: string;
  code: string;
  typeCode: string;
  name: string;
  isPublished: boolean;
  translations: Translations[];
}

interface ProductCategoryType {
  productId: string;
  categories: CategoryType[];
}
export interface LoadCategoryByProductIdResponsePayload {
  products: ProductCategoryType[];
}

interface Translations {
  localCode: string;
  name: string;
  isPublished: boolean;
}

export interface Category {
  categoryId: string;
  category: CategoryData;
}

export interface CategoryResponse {
  categories: CategoryType[];
}

interface CategoryData {
  code: string;
  descriptions: CategoryDescription[];
}

interface CategoryDescription {
  name: string;
  localeCode: string;
}

export interface attributeValue {
  attributeValueId: string;
  attributeValue: {
    attributeId: string;
  };
}

export interface ProductInventoryByAttributes {
  [key: string]: AttributeInventory;
}

interface AttributeInventory {
  [key: string]: number;
}

/**
 * set search term types
 */
export interface SetSearchTermAction {
  payload: {
    searchTerm: string;
  };
  type: typeof SET_SEARCH_TERM;
}

/**
 * update product list by search types
 */

export interface UpdateProductListBySearchAction {
  payload: {
    products: ProductSequenceData[];
  };
  type: typeof UPDATE_PRODUCT_LIST_BY_SEARCH;
}

/**
 * Fetch child product attributes
 */
export const SET_PRODUCTS_LOADING_STATE = "SET_PRODUCTS_LOADING_STATE";

export interface ChildProductAttributeData {
  Product: ChildProductAttribute[];
}

export interface ChildProductAttribute {
  code: string;
  productId: string;
  toParentProducts: {
    parentProductId: string;
  }[];
  attributeValues: attributeValue[];
  attributeValueOverrides: attributeValue[];
  attributeLocalizedValues: attributeValue[];
}

/**
 * Fetch child product variant attributes
 */
export interface LoadChildProductVariantAttributesByParentIdResponsePayload {
  data: ChildProductVariantAttributeByParentIdData;
  variables: GraphQLVariables;
}

export interface ChildProductVariantAttributeByParentIdData {
  Product: ChildProductVariantAttributeByParentId[];
}

export interface ChildProductVariantAttributeByParentId {
  productId: string;
  toChildProducts: ChildProductVariantAttributeByParentIdToChildProduct[];
}

export interface ChildProductVariantAttributeByParentIdNode {
  [name: string]: ChildProductVariantAttributeByParentIdToChildProduct[];
}

export interface ChildProductVariantAttributeByParentIdToChildProduct {
  childProduct: ChildProductVariantAttributeByParentId;
  childProductId: string;
}

export interface ChildProductVariantAttributeByParentId {
  attributeValues: ProductAttributeValue[];
}

export interface LoadInventoryOvelrayAttributesResponsePayload {
  data: CategoryVariantAttribute;
}

export interface CategoryVariantAttribute {
  Attribute: VariantAttribute[];
}

export interface VariantAttribute {
  attributeId: string;
  code: string;
  descriptions: AttributeDescription[];
}

export interface CategoryVariantAttributeNode {
  [name: string]: VariantAttribute[];
}

export interface SortedProductScore {
  productId: string;
  score: number;
}
export interface LoadSortedProductsPayload {
  products: SortedProductScore[];
}
export interface LoadSortedProductsAction {
  payload: LoadSortedProductsPayload;
  type: typeof FETCH_SORTED_PRODUCTS.SUCCESS;
}

export interface LoadProductDetailsResponsePayload {
  data: LowInventoryProductsList;
  variables: {
    page: string;
  };
}

export interface LowInventoryProductsList {
  Inventory: LowInventoryProductData[];
}

export interface LowInventoryProductData {
  productId: string;
  quantity: number;
  product: LowInventoryProduct;
}

export interface LowInventoryProduct {
  code: string;
  productId: string;
  isPublished: boolean;
  descriptions: ProductDescription[];
  overrides: ProductOverride[];
  toParentProducts: ParentProduct[];
}

export interface ParentProduct {
  parentProduct: Product;
}

export interface ResetProductsAction {
  type: typeof RESET_PRODUCTS;
}

/**
 * Date picker types
 */
export const SET_DATE_PICKER_RANGE = "SET_DATE_PICKER_RANGE";

export interface SetDatePickerRangeAction {
  payload: DatePickerPayload;
  type: typeof SET_DATE_PICKER_RANGE;
}

export interface DatePickerPayload {
  startDate: string;
  endDate: string;
}

export interface FetchProductsVariantsPayload {
  productId: string;
  storeId: string;
  localeCode: string;
}

export interface FetchProductsVariantsResponsePayload {
  results: Variants[];
}

export interface FetchProductsVariantsAction {
  payload: FetchProductsVariantsPayload;
  type: string;
}

export interface FetchProductsVariantsResponse {
  payload: FetchProductsVariantsResponsePayload;
  type: string;
}
export const FETCH_PRODUCTS_VARIANTS = {
  REQUEST: "FETCH_PRODUCTS_VARIANTS_REQUEST",
  SUCCESS: "FETCH_PRODUCTS_VARIANTS_SUCCESS",
  FAILURE: "FETCH_PRODUCTS_VARIANTS_FAILURE",
};

export const GET_PRODUCTS_VARIANTS = "GET_PRODUCTS_VARIANTS";

export const PUT_PRODUCT_VARIANT_PUBLISH_STATE =
  "PUT_PRODUCT_VARIANT_PUBLISH_STATE";

export const RESET_PRODUCTS_VARIANTS_STATE = "RESET_PRODUCTS_VARIANTS_STATE";
export const RESET_PRODUCT_VARIANTS = "RESET_PRODUCT_VARIANTS";
export const FETCH_SINGLE_PRODUCT_ANALYTICS = "FETCH_SINGLE_PRODUCT_ANALYTICS";

export const UPDATE_PRODUCT_VARIANT_PUBLISH_STATE = {
  REQUEST: "UPDATE_PRODUCT_VARIANT_PUBLISH_STATE_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_VARIANT_PUBLISH_STATE_SUCCESS",
  DONE: "UPDATE_PRODUCT_VARIANT_PUBLISH_STATE_DONE",
  FAILURE: "UPDATE_PRODUCT_VARIANT_PUBLISH_STATE_FAILURE",
};

export const LOAD_SINGLE_PRODUCT_ANALYTICS_STATE = {
  REQUEST: "LOAD_SINGLE_PRODUCT_ANALYTICS_STATE_REQUEST",
  SUCCESS: "LOAD_SINGLE_PRODUCT_ANALYTICS_STATE_SUCCESS",
  DONE: "LOAD_SINGLE_PRODUCT_ANALYTICS_STATE_DONE",
  FAILURE: "LOAD_SINGLE_PRODUCT_ANALYTICS_STATE_FAILURE",
};
export const RESET_SINGLE_PRODUCT_ANALYTICS_VIEW =
  "RESET_SINGLE_PRODUCT_ANALYTICS_VIEW";

export const RESET_ALL_PRODUCT_ANALYTICS_VIEW =
  "RESET_ALL_PRODUCT_ANALYTICS_VIEW";

export const ANALYTICS_DATA_LOADER = "ANALYTICS_DATA_LOADER";

export interface UpdateProductVariantPublishStateResponse {
  payload: UpdateProductVariantPublishStatePayload;
  type: typeof UPDATE_PRODUCT_VARIANT_PUBLISH_STATE.SUCCESS;
}

export interface UpdateProductVariantPublishStateResponsePayload {
  variantId: string;
}
export interface UpdateProductVariantPublishStatePayload {
  productId: string;
  variantId: string;
  variantCode: string;
  isPublished: boolean;
}
export interface UpdateProductVariantPublishStateAction {
  payload: UpdateProductVariantPublishStatePayload;
  type: string;
}

export interface AddRemoveProductCategoryPayload {
  productId: string;
  sequence: Number;
  categoryId: string;
  parentId?: string;
  storeId?: string;
  isPublished?: boolean;
}

export interface fetchSingleProductPayload {
  productId: string | undefined;
  catalogId: string | undefined;
  localeCode: string | null;
  storeId: string | null;
}

export interface updateProductAddCategoryResponseAction {
  type: typeof ADD_PRODUCT_IN_CATEGORY;
  payload: AddRemoveProductCategoryPayload;
  fetchProductPayload: fetchSingleProductPayload;
}

export interface updateProductRemoveCategoryResponseAction {
  type: typeof REMOVE_PRODUCT_IN_CATEGORY;
}

export interface categorySpecificData {
  categoryId: string;
  leadingColorId?: string;
  leadingImageId?: string;
}

export interface ColorProduct {
  productId: string;
  hasManageableColors: boolean;
  hasSequenceableColors: boolean;
  colorVariationAttributeId?: string;
  colors: ColorData[];
  categorySpecific: categorySpecificData[];
  isRefreshing?: boolean;
}

export interface fetchSingleProductColorPayload {
  productId: string | undefined;
  localeCode: string | null;
  storeId: string | null;
}

export interface loadSingleProductAnalyticsAction {
  payload: singleProductAnlayticsPayload;
  type: typeof FETCH_SINGLE_PRODUCT_ANALYTICS;
}

export interface singleProductAnlayticsPayload {
  productId: string;
  startDate: string;
  endDate: string;
}

export interface productAnalyticsView {
  [x: string]: singleProductAnalyticsView;
}

export interface singleProductAnalyticsView {
  showAnalytics?: boolean;
  loadAnalytics?: boolean;
}

export interface resetSingleProductAnalyticAction {
  type: typeof RESET_SINGLE_PRODUCT_ANALYTICS_VIEW;
  productId: string;
}
