import { productClusterList } from "store/ai-cluster/AiClusterType";
import { ProductSequenceData } from "../store/product-list/ProductListTypes";
import { BoostBuryValues } from "./Constants";

export const getUpdatedProductList = (
  products: ProductSequenceData[],
  productSimilarity: productClusterList[],
  sortType: string,
): ProductSequenceData[] => {
  const productsWithScores = products.map((product) => {
    const match = productSimilarity.find(
      (simProd) => simProd.productId === product.productId,
    );
    return {
      ...product,
      cluster_score: match?.cluster_score ?? 0,
      rank: match?.rank ?? "neutral",
    };
  });

  const filteredProducts = productsWithScores
    .filter((product) => product.rank === sortType)
    .sort((a, b) => b.cluster_score - a.cluster_score);

  const otherProducts = productsWithScores.filter(
    (product) => product.rank !== sortType,
  );

  const updatedProductList =
    sortType === BoostBuryValues.bury
      ? [...otherProducts, ...filteredProducts] // Buried at the end
      : [...filteredProducts, ...otherProducts]; // Boosted at the top

  return updatedProductList;
};
