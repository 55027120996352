export const LOAD_PRODUCT_BADGES_LIST = "LOAD_PRODUCT_BADGES_LIST";

export const FETCH_PRODUCT_BADGES_LIST = {
  REQUEST: "FETCH_PRODUCT_BADGES_LIST_REQUEST",
  SUCCESS: "FETCH_PRODUCT_BADGES_LIST_SUCCESS",
  FAILURE: "FETCH_PRODUCT_BADGES_LIST_FAILURE",
};

export const QUERY_PRODUCT_BADGE = {
  REQUEST: "QUERY_PRODUCT_BADGE_REQUEST",
  SUCCESS: "QUERY_PRODUCT_BADGE_SUCCESS",
  FAILURE: "QUERY_PRODUCT_BADGE_FAILURE",
};
export const LOAD_QUERY_PRODUCT_BADGE = "LOAD_QUERY_PRODUCT_BADGE";

export const QUERY_PRODUCT_BADGE_URL = "QUERY_PRODUCT_BADGE_URL";
export interface LoadProductBadgePayload {
  productIds?: string[];
}

export interface LoadProductBadgeAction {
  payload: LoadProductBadgePayload;
  type: typeof LOAD_QUERY_PRODUCT_BADGE;
}
export interface QueryProductBadgeAction {
  payload: ProductQueryBadges;
  type: typeof QUERY_PRODUCT_BADGE;
}
export interface LoadQueryBadgeAction {
  payload: LoadProductBadgePayload;
  type: string;
}

export interface ProductQueryBadges {
  [x: string]: any;
  productIds: string;
  badges: string[];
}

export interface ProductBadge {
  badgeId: string;
  label: string;
  attributeId: string;
  valueId: string;
  storeId: string;
  precedence: string;
}
