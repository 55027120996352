import { LoadCatalogListAction } from "store/catalog/CatalogTypes";
import {
  LOAD_PRODUCT_BADGES_LIST,
  LoadQueryBadgeAction,
  QUERY_PRODUCT_BADGE,
} from "./ProductBadgeTypes";

export const LoadProductBadgeList = (
  storeId: string,
): LoadCatalogListAction => {
  return {
    type: LOAD_PRODUCT_BADGES_LIST,
    payload: {
      storeId,
    },
  };
};

export const QueryProductBadge = (
  productIds: string[],
): LoadQueryBadgeAction => {
  return {
    type: QUERY_PRODUCT_BADGE.REQUEST,
    payload: {
      productIds,
    },
  };
};
