import React from "react";
import { useField, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setIsAnalytic } from "../../../../src/store/businessRules-list/BusinessRulesListActions";
import { styled, Theme, useTheme } from "@mui/material/styles";
import {
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";

const classes = {
  select: "select",
  formControl: "formControl",
};
const StyledSelect = styled(Select)(({ theme }) => ({
  [`&.${classes.select}`]: {
    maxHeight: 50,
    marginRight: 10,
    width: 324,
    backgroundColor: theme.palette.background.paper,
    margin: "10px 0 1px 0",

    [theme.breakpoints.down("sm")]: {
      width: 250,
    },
    "& .MuiFormLabel-root": {
      width: "158px",
      height: "23px",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      paddingLeft: "1px",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },
    "& .MuiFormLabel-filled.Mui-focused": {
      color: theme.palette.focusColor.main,
      height: "23px",
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    "& .MuiInputBase-root.Mui-focused fieldset": {
      borderColor: theme.palette.focusColor.main,
    },
    "& .MuiInputBase-root input": {
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      height: "50.4px !important",
    },
    "& .MuiInputBase-root.Mui-focused": {
      border: 1.5,
    },
    "& .MuiInputBase-root fieldset": {
      height: 56.4,
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    "& .MuiSelect-root": {
      height: 24.4,
      paddingTop: 16,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.dangerColor.main,
    },
    "& .MuiInputBase-root.Mui-error fieldset": {
      borderColor: theme.palette.dangerColor.main,
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 400,
      fontFamily: "Helvetica !important",
      color: theme.palette.dangerColor.main,
      marginBottom: "-20px !important",
    },
  },
  [`&.${classes.formControl}`]: {
    width: 320,
    marginBottom: 2,
    "&  .MuiInputLabel-formControl": {
      width: "158px",
      height: "23px",
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      paddingLeft: "1px",
    },
    "&  .MuiInputLabel-formControl.Mui-focused": {
      color: theme.palette.focusColor.main,
      height: "23px",
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    "& .MuiInputBase-root.Mui-focused fieldset": {
      borderColor: theme.palette.focusColor.main,
    },
    "& .MuiInputBase-root input": {
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    "& .MuiInputBase-root.Mui-focused": {
      border: "1.5px !important",
    },
  },
}));

export const styles = (theme: Theme) => {
  return {
    label: {
      width: "158px",
      height: "23px",
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      paddingLeft: "1px",
      paddingTop: "11px",
    },
  };
};

const SelectWrapper = ({
  name,
  options,
  currentModalValue,
  defaultValue,
  variant,
  size,
  label,
  ...otherProps
}) => {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (defaultValue !== "") {
      setFieldValue(name, defaultValue);
    }
  }, [defaultValue, name, setFieldValue]);

  const handleSortingPeriod = (value) => {
    setFieldValue("dateRange", "");
    options.forEach((sortRule) => {
      if (sortRule.value === value) {
        dispatch(setIsAnalytic(sortRule.isAnalyticMetric));
        if (
          sortRule.isAnalyticMetric === true &&
          (sortRule.type === "WS" || sortRule.type === "MCS")
        ) {
          setFieldValue("showDateRange", true);
        } else {
          setFieldValue("showDateRange", false);
        }
        return;
      }
    });
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
    if (name === "appliedRule") {
      handleSortingPeriod(value);
    }
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    onChange: handleChange,
  };
  const theme = useTheme();
  const labelStyles = styles(theme);
  return (
    <FormControl
      error={Boolean(meta.error && meta.touched)}
      className={classes.formControl}
      size="small"
      variant="outlined"
    >
      <InputLabel id={`${label}-label`} style={{ ...labelStyles.label }}>
        {label}
      </InputLabel>
      <StyledSelect
        native={true}
        disabled={!options.length}
        className={classes.select}
        variant={variant ?? "outlined"}
        error={Boolean(meta.error && meta.touched)}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          anchorEl: null,
        }}
        label={label}
        {...configSelect}
      >
        <option value="" hidden></option>
        {options?.map((item) => {
          if (currentModalValue !== "") {
            return item.values.map((currentItem) => {
              return (
                <option key={currentItem.value} value={currentItem.value}>
                  {intl.formatMessage({
                    id: `${currentItem.label}`,
                    defaultMessage: `${currentItem.label}`,
                  })}
                </option>
              );
            });
          } else {
            return (
              <option key={item.value} value={item.value}>
                {intl.formatMessage({
                  id: `${item.label}`,
                  defaultMessage: `${item.label}`,
                })}
              </option>
            );
          }
        })}
      </StyledSelect>
      {meta.touched && meta.error && (
        <FormHelperText style={{ margin: 0 }}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectWrapper;
