import {
  ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY,
  FETCH_CLUSTER_LIST,
  FETCH_CLUSTER_REQUEST_BY_CATEGORY,
  FETCH_SIMILAR_PRODUCTS_BY_CATEGORY,
  RESET_SELECTED_SMART_SORT,
  SET_SELECTED_SMART_SORT,
  clusterElmbeddings,
  productClusterList,
  requestClusterList,
} from "./AiClusterType";

export interface ProductsClusterListState {
  productClusterList: productClusterList[];
  addClusterEmbeddings: clusterElmbeddings;
  clusterRequestList: requestClusterList[];
  similarProductsList: productClusterList[];
  isFetchingProductClusterList: boolean;
  isFetchingProductClusterRequestList: boolean;
  isClusterEmbeddingsAdded: boolean;
  isFetchingSimilarProductsList: boolean;
  isSimilarProductFetched: boolean;
  selectedSmartSort: string;
}

const initialState: ProductsClusterListState = {
  productClusterList: [],
  addClusterEmbeddings: {
    requestId: "",
  },
  clusterRequestList: [],
  similarProductsList: [],
  isFetchingProductClusterList: false,
  isFetchingProductClusterRequestList: false,
  isFetchingSimilarProductsList: false,
  isSimilarProductFetched: false,
  isClusterEmbeddingsAdded: false,
  selectedSmartSort: "",
};

export const ProductClusterListReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case FETCH_CLUSTER_LIST.REQUEST: {
      return {
        ...state,
        isFetchingProductClusterList: true,
      };
    }
    case FETCH_CLUSTER_LIST.SUCCESS: {
      const productsClusterList = action.payload
        .results as productClusterList[];
      return {
        ...state,
        isFetchingProductClusterList: false,
        productClusterList: productsClusterList,
      };
    }
    case FETCH_CLUSTER_LIST.FAILURE: {
      return {
        ...state,
        isFetchingProductClusterList: false,
        productClusterList: [],
      };
    }
    case ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY.REQUEST: {
      return {
        ...state,
        isClusterEmbeddingsAdded: true,
        clusterRequestList: [],
        productClusterList: [],
        addClusterEmbeddings: [],
      };
    }
    case ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY.SUCCESS: {
      const requestId = action.payload;
      return {
        ...state,
        addClusterEmbeddings: requestId,
        isClusterEmbeddingsAdded: false,
      };
    }
    case ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY.FAILURE: {
      return {
        ...state,
        isClusterEmbeddingsAdded: false,
      };
    }
    case FETCH_CLUSTER_REQUEST_BY_CATEGORY.REQUEST: {
      return {
        ...state,
        isFetchingProductClusterRequestList: true,
        clusterRequestList: [],
      };
    }
    case FETCH_CLUSTER_REQUEST_BY_CATEGORY.SUCCESS: {
      const data = action.payload.results;
      return {
        ...state,
        isFetchingProductClusterRequestList: false,
        clusterRequestList: data,
      };
    }
    case FETCH_CLUSTER_REQUEST_BY_CATEGORY.FAILURE: {
      return {
        ...state,
        isFetchingProductClusterRequestList: false,
      };
    }
    case FETCH_SIMILAR_PRODUCTS_BY_CATEGORY.REQUEST: {
      return {
        ...state,
        similarProductsList: [],
        isFetchingSimilarProductsList: true,
      };
    }
    case FETCH_SIMILAR_PRODUCTS_BY_CATEGORY.SUCCESS: {
      const data = action.payload.results;
      return {
        ...state,
        isFetchingSimilarProductsList: false,
        isSimilarProductFetched: true,
        similarProductsList: data,
      };
    }
    case FETCH_SIMILAR_PRODUCTS_BY_CATEGORY.FAILURE: {
      return {
        ...state,
        similarProductsList: [],
        isFetchingSimilarProductsList: false,
      };
    }
    case SET_SELECTED_SMART_SORT:
      return {
        ...state,
        selectedSmartSort: action.payload,
      };
    case RESET_SELECTED_SMART_SORT:
      return {
        ...state,
        selectedSmartSort: "",
      };
    default:
      return state;
  }
};
