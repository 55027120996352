import {
  FETCH_PRODUCT_BADGES_LIST,
  QUERY_PRODUCT_BADGE,
  ProductQueryBadges,
} from "./ProductBadgeTypes";

export interface BadgeState {
  badges: any;
}

export interface BadgeState1 {
  badges: BadgeState[];
}

export interface ProductBadgeState {
  productBadge: BadgeState;
}

const initialState: BadgeState1 = {
  badges: [],
};

const initialStateQueryBadge: ProductQueryBadges = {
  productIds: "",
  badges: [],
};

export const ProductBadgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_BADGES_LIST.FAILURE:
      return {
        ...state,
      };
    case FETCH_PRODUCT_BADGES_LIST.SUCCESS:
      return {
        ...state,
        badges: action.payload, // Replace with actual data from action
      };
    default:
      return state;
  }
};

export const ProductQueryBadgeReducer = (
  state = initialStateQueryBadge,
  action,
) => {
  switch (action.type) {
    case QUERY_PRODUCT_BADGE.REQUEST: {
      return {
        ...state,
      };
    }
    case QUERY_PRODUCT_BADGE.SUCCESS: {
      return {
        ...state,
        ...(action.payload as ProductQueryBadges),
      };
    }
    case QUERY_PRODUCT_BADGE.FAILURE: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
